import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/app/ui/button.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/components/common/content-box.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/components/common/partner-carousel.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/components/home/crm-left.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/components/home/crm-right.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/components/home/customer-says.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/components/home/delevery-partners-two.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/components/home/index-panner.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/components/home/Insights.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/components/home/Partners.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/components/home/planning-banner.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/components/home/testi-home.tsx");
import(/* webpackMode: "eager" */ "/var/www/html/invenzolabs-web-nextjs/src/ui/container.tsx")